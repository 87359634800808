
import { defineComponent } from "vue";
import MapButtons from "@/components/MapButtons.vue";
import Dialog from "@/components/dialog";
import Map from "ol/Map";
import View from "ol/View";
import { fromLonLat } from "ol/proj";
import Layers from "../layers";
import "ol/ol.css";
import { LAYER_NAMES, COMPLETE_SUBLAYERS_STRING } from "@/constants";
import Layer from "ol/layer/Layer";
import Source from "ol/source/Source";
import LayerRenderer from "ol/renderer/Layer";
import TileWMS from "ol/source/TileWMS";
import MapBrowserEvent from "ol/MapBrowserEvent";
import ScaleLine from "ol/control/ScaleLine";
import Control from "ol/control/Control";
import PopupOverlay, { POPUP_OVERLAY_ID } from "./PopupOverlay.vue";
import { Coordinate } from "ol/coordinate";
import { defaults as defaultControls } from "ol/control.js";

export interface IInfoFeature {
  properties: { UNIT: number };
}

interface LogoControlOptions {
  target: HTMLElement;
}

interface MapData {
  map: Map | null;
  infoFeatures: IInfoFeature[] | null;
  infoCoordinate: Coordinate | null;
  showInfoDialog: boolean;
  infoDialogTitle: string;
  infoDialogContent: string;
}

class LogoControl extends Control {
  constructor(opt_options: LogoControlOptions) {
    const options = opt_options || {};
    const element = document.createElement("div");

    element.className = "logocontrol ol-unselectable ol-control";
    element.innerHTML =
      '<a href="http://mapy.cz/" target="_blank"><img src="https://api.mapy.cz/img/api/logo.svg" ></a>';

    super({
      element: element,
      target: options.target,
    });
  }
}

export default defineComponent({
  name: "Map",
  components: { MapButtons, PopupOverlay, Dialog },
  mounted() {
    const target = this.$refs.map as HTMLElement;
    this.createMap(target);
    this.addEventHandlers();
  },
  props: {
    sublayers: {
      type: String,
      default:
        "1,2,3,4,5,10,11,12,13,14,15,16,20,21,22,23,24,25,30,31,32,33,35,36,37,38,40,41,42,43,44,45,48,49,50,51,60,70,71,72,73",
    },
  },
  data(): MapData {
    return {
      map: null,
      infoFeatures: null,
      infoCoordinate: null,
      showInfoDialog: false,
      infoDialogTitle: "",
      infoDialogContent: "",
    };
  },
  methods: {
    createLayers() {
      const hillshadeLayer = Layers.hillshade();
      const topoLayer = Layers.topo();
      const geomorphGeoparkLayer = Layers.geomorphologyGeoparkWMTS();
      const geomorphPolygonsLayer = Layers.geomorphologyPolygons();
      const geomorphLayer = Layers.geomorphology();
      const geomorphInfoLayer = Layers.geomorphologyInfo();

      // Set initial visibility
      geomorphGeoparkLayer.setVisible(true);
      geomorphLayer.setVisible(false);
      geomorphPolygonsLayer.setVisible(false);

      return [
        hillshadeLayer,
        topoLayer,
        geomorphGeoparkLayer,
        geomorphPolygonsLayer,
        geomorphLayer,
        geomorphInfoLayer,
      ];
    },
    createView() {
      const zoom = 12;
      const minZoom = 10;
      const maxZoom = 16;
      const centerCrds = [13.37953, 49.1428];
      const center = fromLonLat(centerCrds);

      const extent = [
        1469834.5874150463, 6279558.513877497, 1508970.345891609,
        6318694.27235406,
      ];

      return new View({
        center,
        zoom,
        minZoom,
        maxZoom,
        extent,
        constrainOnlyCenter: true,
      });
    },
    createMap(target: HTMLElement) {
      const layers = this.createLayers();
      const view = this.createView();
      const controls = defaultControls({
        attributionOptions: { collapsible: false },
      });
      const scaleLineControl = new ScaleLine();
      const logoControl = new LogoControl({ target });

      this.map = new Map({
        target,
        layers,
        view,
        controls,
      });

      this.map.addControl(scaleLineControl);
      this.map.addControl(logoControl);
      this.$store.dispatch("setMap", this.map);
    },
    getLayer(layerName: string): Layer<Source, LayerRenderer<any>> | undefined {
      const layers = this.map?.getAllLayers();
      return layers?.find((layer) => layer.get("name") == layerName);
    },
    getPopupOverlay() {
      return this.map?.getOverlayById(POPUP_OVERLAY_ID);
    },
    addEventHandlers() {
      this.map?.on("singleclick", this.onSingleClick);
    },
    onSingleClick(event: MapBrowserEvent<any>) {
      if (this.map === null) {
        return;
      }

      const view = this.map.getView();
      const coordinate = event.coordinate;
      const geomorphLayer = this.getLayer(LAYER_NAMES.GEOMORPHOLOGY_INFO);
      const geomorphSource = geomorphLayer?.getSource() as TileWMS;
      const viewResolution = view.getResolution();
      const overlay = this.getPopupOverlay();

      if (!geomorphSource || !viewResolution || !overlay) {
        return;
      }

      const url = geomorphSource.getFeatureInfoUrl(
        event.coordinate,
        viewResolution,
        "EPSG:3857",
        {
          INFO_FORMAT: "application/json",
          FEATURE_COUNT: "20",
          PROPERTYNAME: "UNIT", // to speed up the request, use PROPERTYNAME param to avoid receiving feature geometry
        }
      );

      if (url) {
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            this.infoFeatures = data.features;
            this.infoCoordinate = coordinate;
            overlay.setPosition(coordinate);
          });
      }
    },
    updateParams(sublayers: string) {
      let filter = "";
      if (sublayers.length === 0) {
        filter = "INDEX=0";
      } else {
        filter = `INDEX in (${sublayers})`;
      }

      const geomorphInfoLayer = this.getLayer(LAYER_NAMES.GEOMORPHOLOGY_INFO);
      const geomorphInfoSource = geomorphInfoLayer?.getSource() as TileWMS;
      geomorphInfoSource.updateParams({
        CQL_FILTER: filter,
      });

      const geomorphGeoparkLayer = this.getLayer(
        LAYER_NAMES.GEOMORPHOLOGY_WMTS
      );
      const geomorphLayer = this.getLayer(LAYER_NAMES.GEOMORPHOLOGY);
      const geomorphPolygonsLayer = this.getLayer(
        LAYER_NAMES.GEOMORPHOLOGY_POLYGONS
      );

      if (COMPLETE_SUBLAYERS_STRING === sublayers) {
        geomorphGeoparkLayer?.setVisible(true);
        geomorphLayer?.setVisible(false);
        geomorphPolygonsLayer?.setVisible(false);
      } else {
        geomorphGeoparkLayer?.setVisible(false);
        const geomorphSource = geomorphLayer?.getSource() as TileWMS;
        geomorphSource.updateParams({
          CQL_FILTER: `${filter};${filter};${filter};${filter};${filter};${filter}`,
        });
        geomorphLayer?.setVisible(true);

        const geomorphPolySource =
          geomorphPolygonsLayer?.getSource() as TileWMS;
        geomorphPolySource.updateParams({
          CQL_FILTER: filter,
        });
        geomorphPolygonsLayer?.setVisible(true);
      }
    },
    onLayersClick() {
      this.$emit("layersClick");
    },
    onMenuClick() {
      this.$emit("menuClick");
    },
    showGeneticTypeInfo(geneticTypeId: string) {
      const titleTranslation = `legend.geneticTypes.${geneticTypeId}`;
      const contentTranslation = `geneticTypesDescription.${geneticTypeId}`;
      this.infoDialogTitle = this.$t(titleTranslation);
      this.infoDialogContent = this.$t(contentTranslation);

      this.showInfoDialog = true;
    },
    showReliefShapeInfo(reliefShapeId: string) {
      const titleTranslation = `legend.reliefShapes.${reliefShapeId}`;
      const contentTranslation = `reliefShapesDescription.${reliefShapeId}`;
      this.infoDialogTitle = this.$t(titleTranslation);
      this.infoDialogContent = this.$t(contentTranslation);

      this.showInfoDialog = true;
    },
  },
  watch: {
    sublayers(newSublayers: string, oldSublayers: string) {
      if (newSublayers === oldSublayers) return;
      this.updateParams(newSublayers);
    },
  },
});
