const GEOSERVER_URL = "https://api.geomapasumavy.cz/geoserver/";

const URLS = {
  GEOSERVER: GEOSERVER_URL,
  GEOMORPHOLOGY: `${GEOSERVER_URL}geopark-sumava/wms`,
  GEOMORPHOLOGY_WMTS: `${GEOSERVER_URL}geopark-sumava/gwc/service/wmts`,
};

const LAYER_NAMES = {
  GEOMORPHOLOGY: "geomorphology",
  GEOMORPHOLOGY_WMTS: "geomorphologyWMTS",
  GEOMORPHOLOGY_POLYGONS: "geomorphologyPolygons",
  GEOMORPHOLOGY_INFO: "geomorphologyInfo",
  TOPO: "topo",
  HILLSHADE: "hillshade",
  MY_LOCATION: "myLocation",
};

const MAPTILER_API_KEY = "VG11MmoPWG6NJRj4lusl";
const MAPY_CZ_API_KEY = "QIPUIKNBssOtNoV265hXbaIFWp-Eqr7JftJ3uk6VF48";

const COMPLETE_SUBLAYERS_STRING =
  "1,2,3,4,5,10,11,12,13,14,15,16,20,21,22,23,24,25,30,31,32,33,35,36,37,38,38,38,40,41,42,43,44,45,48,49,50,51,60,70,71,72,73";

export {
  URLS,
  LAYER_NAMES,
  MAPTILER_API_KEY,
  MAPY_CZ_API_KEY,
  COMPLETE_SUBLAYERS_STRING,
};
