import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79b5490e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layer-title" }
const _hoisted_2 = { class: "legend" }
const _hoisted_3 = { class: "legend-group-wrapper" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "legend-group-title" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "legend-group-content" }
const _hoisted_8 = { class: "legend-item-text" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "legend-item-images" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "legend-item-index" }
const _hoisted_13 = { class: "legend-item-label" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "layer-title" }
const _hoisted_16 = { class: "layer-title" }
const _hoisted_17 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayerOpacitySlider = _resolveComponent("LayerOpacitySlider")!
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", {
    class: "container",
    style: _normalizeStyle({ 'max-height': _ctx.maxHeight })
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("layers.titles.geomorphology")), 1),
    _createVNode(_component_LayerOpacitySlider, {
      class: "layer-opacity-slider",
      layerNames: [
        _ctx.LAYER_NAMES.GEOMORPHOLOGY_WMTS,
        _ctx.LAYER_NAMES.GEOMORPHOLOGY,
        _ctx.LAYER_NAMES.GEOMORPHOLOGY_POLYGONS,
      ]
    }, null, 8, ["layerNames"]),
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (value, key) => {
        return (_openBlock(), _createElementBlock("li", {
          key: key,
          class: "legend-group"
        }, [
          _createElementVNode("span", _hoisted_3, [
            _createElementVNode("span", {
              class: "legend-group-icon",
              onClick: ($event: any) => (_ctx.onLegendGroupClick(key))
            }, [
              (value.visible)
                ? (_openBlock(), _createBlock(_component_mdicon, {
                    key: 0,
                    name: "eye",
                    class: "eye eye-active",
                    size: _ctx.iconSize
                  }, null, 8, ["size"]))
                : (_openBlock(), _createBlock(_component_mdicon, {
                    key: 1,
                    name: "eye-off",
                    class: "eye",
                    size: _ctx.iconSize
                  }, null, 8, ["size"]))
            ], 8, _hoisted_4),
            _createElementVNode("span", _hoisted_5, _toDisplayString(value.geneticTypeLabel), 1),
            (_ctx.itemsInfoAvailable)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                  _createVNode(_component_mdicon, {
                    name: "information-outline",
                    class: "info-icon",
                    size: _ctx.iconSize,
                    onClick: ($event: any) => (_ctx.showGeneticTypeInfo(key))
                  }, null, 8, ["size", "onClick"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("ul", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortReliefShapes(value.reliefShapes), (item) => {
              return (_openBlock(), _createElementBlock("li", {
                key: item.reliefShapeIndex,
                class: "legend-item"
              }, [
                _createElementVNode("span", _hoisted_8, [
                  _createElementVNode("span", {
                    class: "legend-item-icon",
                    onClick: ($event: any) => (_ctx.onLegendItemClick(key, item.reliefShapeIndex))
                  }, [
                    (item.visible)
                      ? (_openBlock(), _createBlock(_component_mdicon, {
                          key: 0,
                          name: "eye",
                          class: "eye eye-active",
                          size: _ctx.smallIconSize
                        }, null, 8, ["size"]))
                      : (_openBlock(), _createBlock(_component_mdicon, {
                          key: 1,
                          name: "eye-off",
                          class: "eye",
                          size: _ctx.smallIconSize
                        }, null, 8, ["size"]))
                  ], 8, _hoisted_9),
                  _createElementVNode("span", _hoisted_10, [
                    _createElementVNode("span", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.img, (image, key) => {
                        return (_openBlock(), _createElementBlock("span", { key: key }, [
                          _createElementVNode("img", {
                            src: _ctx.getLegendImgUrl(image),
                            alt: "legend image"
                          }, null, 8, _hoisted_11)
                        ]))
                      }), 128))
                    ])
                  ]),
                  _createElementVNode("span", _hoisted_12, _toDisplayString(item.reliefShapeIndex), 1),
                  _createElementVNode("span", _hoisted_13, _toDisplayString(item.reliefShapeLabel), 1),
                  (_ctx.itemsInfoAvailable)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                        _createVNode(_component_mdicon, {
                          name: "information-outline",
                          class: "info-icon",
                          size: _ctx.smallIconSize,
                          onClick: ($event: any) => (_ctx.showReliefShapeInfo(item.reliefShapeIndex))
                        }, null, 8, ["size", "onClick"])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            }), 128))
          ])
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t("layers.titles.topo")), 1),
    _createVNode(_component_LayerOpacitySlider, {
      class: "layer-opacity-slider",
      layerNames: [_ctx.LAYER_NAMES.TOPO]
    }, null, 8, ["layerNames"]),
    _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t("layers.titles.hillshade")), 1),
    _createVNode(_component_LayerOpacitySlider, {
      class: "layer-opacity-slider",
      layerNames: [_ctx.LAYER_NAMES.HILLSHADE]
    }, null, 8, ["layerNames"]),
    (_ctx.showInfoDialog)
      ? (_openBlock(), _createBlock(_component_Dialog, {
          key: 0,
          maxWidth: "800px",
          contentHeight: "150px",
          title: _ctx.infoDialogTitle,
          onCloseClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showInfoDialog = false))
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", { innerHTML: _ctx.infoDialogContent }, null, 8, _hoisted_17)
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true)
  ], 4))
}