import TileLayer from "ol/layer/Tile";
import TileJSON from "ol/source/TileJSON";
import TileWMS from "ol/source/TileWMS";
import { get as getProjection } from "ol/proj";
import { URLS, LAYER_NAMES, MAPY_CZ_API_KEY } from "./constants";
import WMTS from "ol/source/WMTS";
import WMTSTileGrid from "ol/tilegrid/WMTS";

export default class Layers {
  static hillshade(): TileLayer<TileWMS> {
    const params = {
      layers: "dmr5g:GrayscaleHillshade",
      TILED: true,
    };

    const attributions =
      "© <a href='https://www.cuzk.cz/' target='_blank' rel='noopener noreferrer'>ČÚZK</a>";

    const source = new TileWMS({
      url: "https://ags.cuzk.cz/arcgis2/services/dmr5g/ImageServer/WMSServer/",
      params,
      attributions,
    });

    const properties = { name: LAYER_NAMES.HILLSHADE };

    return new TileLayer({
      source,
      properties,
    });
  }

  static topo(): TileLayer<TileJSON> {
    const source = new TileJSON({
      url: `https://api.mapy.cz/v1/maptiles/outdoor/tiles.json?apikey=${MAPY_CZ_API_KEY}`,
      crossOrigin: "anonymous",
    });

    const properties = { name: LAYER_NAMES.TOPO };

    return new TileLayer({
      source,
      opacity: 0.4,
      properties,
    });
  }

  static geomorphologyPolygons(): TileLayer<TileWMS> {
    const url = URLS.GEOMORPHOLOGY;
    const LAYERS = "geopark_polygons";
    const attributions =
      "© <a href='http://www.georespect.cz/' target='_blank' rel='noopener noreferrer'>GEORESPECT Ltd.</a>";
    const params = { LAYERS, tiled: true };

    const source = new TileWMS({
      url,
      params,
      attributions,
      serverType: "geoserver",
    });

    const properties = { name: LAYER_NAMES.GEOMORPHOLOGY_POLYGONS };

    return new TileLayer({
      source,
      opacity: 1,
      properties,
    });
  }

  static geomorphologyGeoparkWMTS(): TileLayer<WMTS> {
    const url = URLS.GEOMORPHOLOGY_WMTS; // Update to your GeoServer WMTS URL
    const layer = "geopark-sumava:geopark-sumava"; // GeoServer WMTS layer name
    const matrixSet = "EPSG:4326"; // Updated for EPSG:4326 projection
    const projection = getProjection("EPSG:4326") || undefined;

    const resolutions = [
      0.703125, // Zoom level 0
      0.3515625, // Zoom level 1
      0.17578125, // Zoom level 2
      0.087890625, // Zoom level 3
      0.0439453125, // Zoom level 4
      0.02197265625, // Zoom level 5
      0.010986328125, // Zoom level 6
      0.0054931640625, // Zoom level 7
      0.00274658203125, // Zoom level 8
      0.001373291015625, // Zoom level 9
      0.0006866455078125, // Zoom level 10
      0.00034332275390625, // Zoom level 11
      0.000171661376953125, // Zoom level 12
      0.0000858306884765625, // Zoom level 13
      0.00004291534423828125, // Zoom level 14
      0.000021457672119140625, // Zoom level 15
      0.000010728836059570313, // Zoom level 16
    ];

    const matrixIds = resolutions.map((_, idx) => `EPSG:4326:${idx}`); // Use GeoServer's typical format for matrix IDs

    const wmtsSource = new WMTS({
      url: `${url}/gwc/service/wmts`, // GeoServer WMTS endpoint
      layer,
      matrixSet,
      format: "image/png8",
      projection: projection,
      tileGrid: new WMTSTileGrid({
        origin: [-180, 90], // Origin for EPSG:4326
        resolutions: resolutions,
        matrixIds: matrixIds,
        tileSize: [256, 256],
        extent: [
          13.146943966389685, 49.030985492739084, 13.607114894717064,
          49.267532718604684,
        ],
      }),
      style: "", // Use the default style or specify one
      attributions:
        "© <a href='http://www.georespect.cz/' target='_blank' rel='noopener noreferrer'>GEORESPECT Ltd.</a>",
    });

    const properties = { name: LAYER_NAMES.GEOMORPHOLOGY_WMTS };

    return new TileLayer({
      source: wmtsSource,
      opacity: 1,
      properties,
    });
  }

  static geomorphology(): TileLayer<TileWMS> {
    const url = URLS.GEOMORPHOLOGY;
    const LAYERS =
      "geopark_polygons_hatch,geopark_morpholineaments,geopark_lines,geopark_drainage,geopark_cannal,geopark_points";
    const attributions =
      "© <a href='http://www.georespect.cz/' target='_blank' rel='noopener noreferrer'>GEORESPECT Ltd.</a>";
    const params = { LAYERS, TILED: true };

    const source = new TileWMS({
      url,
      params,
      attributions,
      serverType: "geoserver",
    });

    const properties = { name: LAYER_NAMES.GEOMORPHOLOGY };

    return new TileLayer({
      source,
      opacity: 1,
      properties,
    });
  }

  static geomorphologyInfo(): TileLayer<TileWMS> {
    const url = URLS.GEOMORPHOLOGY;
    const LAYERS = "geopark-sumava:geopark-sumava";
    const attributions =
      "© <a href='http://www.georespect.cz/' target='_blank' rel='noopener noreferrer'>GEORESPECT Ltd.</a>";
    const params = { LAYERS, TILED: false };
    const serverType = "geoserver";
    const opacity = 0;
    const properties = { name: LAYER_NAMES.GEOMORPHOLOGY_INFO };

    const source = new TileWMS({
      url,
      params,
      serverType,
      attributions,
    });

    return new TileLayer({
      source,
      opacity,
      properties,
      visible: false,
    });
  }
}
